import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

export const SocialNetworksData = [
  {
    icon: <FontAwesomeIcon icon={faTwitter} />,
    url: 'https://twitter.com/VeryGoodstake?s=20&t=wIVbMyFxILRJPVSSqSlnkQ'
  },
  {
    icon: <FontAwesomeIcon icon={faInstagram} />,
    url: 'https://instagram.com/verygoodstake?igshid=YmMyMTA2M2Y='
  }
]