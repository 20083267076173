import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandHoldingDollar, faCoins, faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons'

export const FlowStages = [
  {
    icon: <FontAwesomeIcon icon={faMoneyBillTransfer} />,
    text: 'STAKE TO A\n CAUSE/PROJECT'
  },
  {
    icon: <FontAwesomeIcon icon={faCoins} />,
    text: 'GENERATE \n REWARDS'
  },
  {
    icon: <FontAwesomeIcon icon={faHandHoldingDollar} />,
    text: 'DISTRIBUTE \nBASIC INCOME'
  }
]

export const Services = [
  {
    title : 'Sustainibility',
    text : 'Reduces the amount of funds that need to be raised on a regular basis'
  },
  {
    title : 'Predictability',
    text : 'Donors will have a long-term visibility of the funds they will receive'
  },
  {
    title : 'Transparency',
    text : 'Reduces the amount of funds that need to be raised on a regular basis'
  }
]