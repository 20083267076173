import { Route, Routes } from "react-router-dom";

import "./App.scss";
import Footer from "./components/footer/footer.components";
import Header from "./components/header/header.component";
import Home from "./routes/home/home.component";

function App() {
  return (
    <div className="app-container">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
