import { SocialNetworksData } from "./footer.data";
import "./footer.styles.scss";
import GoodLogo from "../../assets/imgs/logos/GOODSTAKE_logo_white.png";

const SocialNetworks = () => {
  return (
    <div className="social-networks">
      {SocialNetworksData.map((social) => {
        return (
          <div className="social-networks__item">
            <a href={social.url} target="_blanck">
              {social.icon}
            </a>
          </div>
        );
      })}
    </div>
  );
};

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__container">
        <div className="footer__container-left">
          <img src={GoodLogo} alt="Goodstake" />
        </div>
        <div className="footer__container-right">
          <SocialNetworks />
          <p>©GOODSTAKE 2022</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
