import { FlowStages, Services } from "./home.data";

import EthLogo from "../../assets/imgs/logos/ETH_Global_logo.png";
import SSVLogo from "../../assets/imgs/logos/ssv-network-full-logo 1.png";
import WorldLogo from "../../assets/imgs/logos/Worldcoin_Lockup 1.png";
import CoinbaseLogo from "../../assets/imgs/logos/coinbase 1.png";

import "./home.styles.scss";

type FlowStageProps = {
  icon: any;
  text: string;
};
const FlowStage = ({ icon, text }: FlowStageProps) => {
  return (
    <div className="flow-stage">
      <div className="flow-stage__icon-container">
        <div className="flow-stage__icon-container--icon">{icon}</div>
      </div>
      <div className="flow-stage__text">{text}</div>
    </div>
  );
};

type ServiceProps = {
  title: string;
  text: String;
};

const Service = ({ title, text }: ServiceProps) => {
  return (
    <div className="service">
      <div className="service__title">{title}</div>
      <div className="service__text">{text}</div>
    </div>
  );
};

const EthGlobal = () => {
  return (
    <div className="eth-global">
      <h2>MADE AT</h2>
      <img src={EthLogo} alt="Eth Global" />
      <h2>BOGOTÁ</h2>
    </div>
  );
};
const Home = () => {
  return (
    <div className="home">
      <div className="home__title">
        <span className="text-title">Fund once, help forever!</span>
        {/* <button className="good-button">stake here</button> */}
      </div>
      <div className="home__flow">
        <div className="home__flow--items">
          {FlowStages.map((flow) => (
            <>
              <FlowStage {...flow} />
              <div className="questionNumberLine" />
            </>
          ))}
        </div>
      </div>
      <div className="home__ubi">
        <span className="text-title">The future of UBI is here</span>
        <p>
          We want to build a{" "}
          <b>
            DeFi platform that allows the sustainable implementation of Basic
            Income programs
          </b>
          . Through Goodstake, donations coming from organizations will no
          longer be directly adjudicated to the intended recipients but will be
          put in Ethereum staking pools where they will generate rewards. This
          way, the principal will remain stable (or grow) and the rewards are
          regularly distributed as Basic Income.
        </p>
      </div>
      <div className="home__services">
        <h3>OUR SERVICES SOLVES</h3>
        <div className="home__services--items">
          {Services.map((service) => (
            <Service {...service} />
          ))}
        </div>
      </div>
      <div className="home__made-at">
        <EthGlobal />
        <div className="home__made-at--description">
          <span>
            We created our Proof of Concept at ETH Global Hackathon last October
            in Bogotá, and we’re working on two projects with rural and
            indigenous communities in Colombia and Mexico to implement
            Goodstake, aiming to provide food security and education.
          </span>
        </div>
      </div>
      <div className="home__partners">
        <h2>PLATFORM PARTNERS</h2>
        <div className="home__partners--list">
          <a href="https://ssv.network/" target='_blanck' className="home__partners--list-partner">
            <img src={SSVLogo} alt="SSV Network" />
          </a>
          <a href="https://worldcoin.org/" target='_blanck' className="home__partners--list-partner">
            <img src={WorldLogo} alt="Wordlcoin" />
          </a>
          <a href="https://www.coinbase.com/" target='_blanck' className="home__partners--list-partner">
            <img src={CoinbaseLogo} alt="Coinbase | Wallet" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
